
.cloud {
    width:350px;
    height:120px;
    background:#fff;
    background:linear-gradient(to left,#fff 100%);
    border-radius:100px;
    position:absolute;
    margin:120px auto 20px;
    z-index:-1;
    transition:1s ease
}
.cloud:after,
.cloud:before {
    content:"";
    position:absolute;
    background:#fff;
    z-index:-1
}
.cloud:after {
    width:100px;
    height:100px;
    top:-50px;
    left:50px;
    border-radius:100px;
}
.cloud:before {
    width:180px;
    height:180px;
    top:-90px;
    right:50px;
    border-radius:200px;
}
.x1 {
    top:-50px;
    left:100px;
    transform:scale(.3);
    opacity:.9;
    animation:moveclouds 15s linear infinite;
}
.x2 {
    left:250px;
    top:30px;
    transform:scale(.6);
    opacity:.6;
    animation:moveclouds 25s linear infinite;
}
.x4 {
    left:470px;
    bottom:20px;
    transform:scale(.75);
    opacity:.75;
    animation:moveclouds 18s linear infinite;
}
@keyframes moveclouds {
    0% {
        margin-left:1000px
    }
    to {
        margin-left:-1000px
    }
}
@media screen and (max-width:657px) {
    .c {
        width:100%;
        margin:10px auto
    }
}

.MuiCardHeader-root .MuiTypography-colorTextSecondary,
.MuiCardHeader-root{
    background-color: #ec4f30;
    color: white;
}

.delivered td.actions svg,
.delivered td {
    color: rgba(67, 160, 71, 0.8);
    font-weight: 700;
}

.delivered.MuiCardHeader-root {
    background-color: rgba(67, 160, 71, 1);
}

.danger,
.notdelivered td.actions svg,
.notdelivered td{
    color: rgba(229, 57, 53, 0.8);
    font-weight: 700;
}

.notdelivered.MuiCardHeader-root {
    background-color: rgba(229, 57, 53, 1);
}

.transit td.actions svg,
.transit td {
    color: rgba(251, 140, 0, 0.8);
    font-weight: 700;
}

.transit.MuiCardHeader-root {
    background-color: rgba(251, 140, 0, 1);
}


.p0 {
    padding: 0 !important;
}

.table tr:nth-child(2n){
    background-color: #f5f5f5;
}

.rowbg:not(.delivered):not(.notdelivered):not(.transit) {
    background-color: #f5f5f5;
}

@keyframes animline {
 0% {
  width:0
 }
 to {
  width:100%
 }
}

.animtrack .spacer::before,
.animtrack .icon::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background: #ec4f30;
    animation: animline;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.animtrack:nth-child(2) .spacer::before{
    animation-delay: 0.5s;
}
.animtrack:nth-child(2) .icon::before{
    animation-delay: 1s;
}

.animtrack:nth-child(3) .spacer::before{
    animation-delay: 1.5s;
}
.animtrack:nth-child(3) .icon::before{
    animation-delay: 2s;
}

.animtrack:nth-child(4) .spacer::before{
    animation-delay: 2.5s;
}
.animtrack:nth-child(4) .icon::before{
    animation-delay: 3s;
}

@keyframes animLast {
    0% {
        top:0
    }
    50% {
        top:-5px
    }
    to {
        top:0
    }
}
.animtrack.last .icon {
    animation: animLast .7s infinite;
    animation-timing-function:ease
}

.requiredbg,
.MuiInputBase-root.Mui-error{
    background-color: rgba(255,0,0,0.1);
}

.filterRow{
    background-color: #ec4f30 !important;
}
.filterRow MuiFormLabel-root.Mui-focused,
.filterRow *{
    color: white !important;
}
.filterRow .MuiInput-underline::before{
    border-bottom: 1px solid rgba(255,255,255, 0.8) !important;
}
.filterRow .MuiInput-underline::after{
    border-bottom: 1px solid black !important;
}

tr.cancel{
    position: relative;
    opacity: 0.5;
}

tr.cancel::after{
    position: absolute;
    width: calc(100% - 16px);
    height: 2px;
    left: 8px;
    top: calc(50% - 1px);
    background-color: rgba(0,0,0,0.5);
    content: "";
}
.activeDiscount{
    background: rgb(129, 199, 132) !important;
}